const INFO = {
    main: {
        title: "Portfolio | Gunawan",
        name: "Gunawan.",
        email: "arzibusiness1@gmail.com",
        logo: "../logo.png",
    },

    socials: {
        twitter: "https://twitter.com/",
        github: "https://github.com/HoooMaC/",
        linkedin: "https://linkedin.com/in/wawan-camoh/",
        instagram: "https://www.instagram.com/guna_wan523/",
    },

    homepage: {
        title: "Hi!   I'm Gunawan.",
        description:
            "I am a full-stack developer that really love in" +
            " programming world.",
    },

    about: {
        title: "Nice to meet you, I'm Gunawan",
        description:
            "I am a full-stack developer specializing in React.js. I have a background in creating scalable, secure, and reliable web applications using various frameworks and technologies. I thrive on solving complex problems and continuously learning new skills. My passion lies in producing high-quality code that adheres to best practices and industry standards. I am constantly seeking opportunities to grow and advance as a developer.",
    },

    articles: {
        title: "I'm passionate about pushing the boundaries of what's possible and inspiring the next generation of innovators.",
        description:
            "Chronological collection of my long-form thoughts on programming, leadership, product design, and more.",
    },

    projects: [
        {
            title: "Raden Patah",
            description:
                "An official websites of masjid raden patah of Brawijaya" +
                " University. Made with Laravel.",
            logo: "mrp.png",
            linkText: "View Website",
            link: "https://radenpatah.ub.ac.id",
        },

        {
            title: "Invitation Website",
            description:
                "An invitational wedding website that I made from scratch" +
                " with React for the front-end and Laravel for the back-end.",
            logo: "wedding-ring.png",
            linkText: "View Website",
            link: "https://alham-seva.com",
        },

        {
            title: "This Portfolio Website",
            description:
                "This portfolio website was made above React Js framework.",
            logo: "react.png",
            // linkText: "View Project",
            // link: "https://github.com",
        },
    ],
};

export default INFO;
