import React, {useEffect} from "react";

import INFO from "../data/user";

import "./styles/about.css";

const About = () => {
    return (
        <React.Fragment>
            <div className="content-wrapper">
                <div className="about-container">
                    <div className="about-main">
                        <div className="about-right-side">


                            <div className="about-image-container">
                                <div className="about-image-wrapper">
                                    <img
                                        src="about.jpg"
                                        alt="about"
                                        className="about-image"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="about-left-side">

                            <div className="about-title">
                                {INFO.about.title}
                            </div>

                            <div className="about-subtitle">
                                {INFO.about.description}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default About;
