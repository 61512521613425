import React, {useState, useEffect, useRef} from "react";
import {Helmet} from "react-helmet";

import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGithub,
    faInstagram, faLinkedin,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import AllProjects from "../components/projects/allProjects";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/homepage.css";
import About from "./about";
import Contact from "./contact";

const Homepage = () => {
    const [activeSection, setActiveSection] = useState('');

    const [stayLogo, setStayLogo] = useState(false);
    const [logoSize, setLogoSize] = useState(80);
    const [oldLogoSize, setOldLogoSize] = useState(80);

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('div[id^="section"]');
            sections.forEach(section => {
                const rect = section.getBoundingClientRect();
                if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
                    setActiveSection(section.id);
                }
            });

            let scroll = Math.round(window.pageYOffset, 2);

            let newLogoSize = 80 - (scroll * 4) / 10;

            if (newLogoSize < oldLogoSize) {
                if (newLogoSize > 40) {
                    setLogoSize(newLogoSize);
                    setOldLogoSize(newLogoSize);
                    setStayLogo(false);
                } else {
                    setStayLogo(true);
                }
            } else {
                setLogoSize(newLogoSize);
                setStayLogo(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [logoSize, oldLogoSize]);

    const currentSEO = SEO.find((item) => item.page === "home");

    const logoStyle = {
        display: "flex",
        position: stayLogo ? "fixed" : "relative",
        top: stayLogo ? "3vh" : "auto",
        zIndex: 999,
        border: stayLogo ? "1px solid white" : "none",
        borderRadius: stayLogo ? "50%" : "none",
        boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>{INFO.main.title}</title>
                <meta name="description" content={currentSEO.description}/>
                <meta
                    name="keywords"
                    content={currentSEO.keywords.join(", ")}
                />
            </Helmet>

            <div className="page-content">
                <div className="nav-container">
                    <nav className="navbar">
                        <div className="nav-background">
                            <ul className="nav-list">
                                <li
                                    className={
                                        activeSection === "sectionhomepage"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }
                                >
                                    <a href="#sectionhomepage">Home</a>
                                </li>
                                <li
                                    className={
                                        activeSection === "sectionabout"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }
                                >
                                    <a href="#sectionabout">About</a>
                                </li>
                                <li
                                    className={
                                        activeSection === "sectionprojects"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }
                                >
                                    <a href="#sectionprojects">Projects</a>
                                </li>
                                <li
                                    className={
                                        activeSection === "sectioncontact"
                                            ? "nav-item active"
                                            : "nav-item"
                                    }
                                >
                                    <a href="#sectioncontact">Contact</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
                <div className="content-wrapper">
                    <div id="sectionhomepage"
                         className="homepage-logo-container">
                        <div style={logoStyle}>
                            <Logo width={logoSize} link={false}/>
                        </div>
                    </div>

                    <div className="homepage-container">
                        <div className="homepage-first-area">
                            <div className="homepage-first-area-left-side">
                                <div className="title homepage-title">
                                    {INFO.homepage.title}
                                </div>

                                <div className="subtitle homepage-subtitle">
                                    {INFO.homepage.description}
                                </div>
                            </div>

                            <div className="homepage-first-area-right-side">
                                <div className="homepage-image-container">
                                    <div className="homepage-image-wrapper">
                                        <img
                                            src="homepage.jpg"
                                            alt="about"
                                            className="homepage-image"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="homepage-socials">
                            <a
                                href={INFO.socials.github}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faGithub}
                                    className="homepage-social-icon"
                                />
                            </a>
                            <a
                                href={INFO.socials.linkedin}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faLinkedin}
                                    className="homepage-social-icon"
                                />
                            </a>
                            <a
                                href={INFO.socials.instagram}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faInstagram}
                                    className="homepage-social-icon"
                                />
                            </a>
                            <a
                                href={`mailto:${INFO.main.email}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <FontAwesomeIcon
                                    icon={faEnvelope}
                                    className="homepage-social-icon"
                                />
                            </a>
                        </div>


                    </div>

                    <div id="sectionabout" className="section about-section">
                        <About />
                    </div>


                    <div id="sectionprojects"
                         className="section projects-section">
                        <AllProjects/>
                    </div>

                    <div id="sectioncontact"
                         className="section contact-section">
                        <Contact />
                    </div>

                    <div className="page-footer">
                        <Footer/>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default Homepage;
