import React, {useEffect} from "react";

import "./styles/navBar.css";

const NavBar = ({active, sectionRefList}) => {

    useEffect(() => {
        console.log("sectionRefList", sectionRefList);
    }, []);
    const scrollToSection = (sectionRef) => {
        sectionRef.current.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <React.Fragment>

        </React.Fragment>
    );
};

export default NavBar;
