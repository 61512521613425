import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";
import SEO from "../data/seo";

import "./styles/contact.css";

const Contact = () => {
    return (
        <React.Fragment>

            <div className="contact-container">
                <div className="contact-title">
                    Get in Touch
                </div>

                <div className="contact-subtitle">
                    <p>

                        Thank you for your to my website. I welcome your
                        feedback,
                        questions, and
                        suggestions. If you have a specific question or
                        comment, please feel free to email me directly at
                        &nbsp;{" "}
                        <a href={`mailto:${INFO.main.email}`}>
                            {INFO.main.email}
                        </a>
                        . I'll response your message as soon as possible,
                        Or, if you prefer to connect on
                        social media, you can find me on{" "}
                        <a
                            href={INFO.socials.linkedin}
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                        . If you want to know me deeply, you can download my cv
                        by
                        pressing button below
                    </p>
                    <p>
                        Thanks again for your interest, and I look forward

                        to hearing from you!
                    </p>
                </div>
                <a className="download-cv">Download CV</a>
            </div>

        </React.Fragment>
    );
};

export default Contact;
