import React from "react";
import { Link } from "react-router-dom";

import "./styles/footer.css";

const Footer = () => {
	return (
		<React.Fragment>
			<div className="footer">
				<p>Made by Gunawan</p>

			</div>
		</React.Fragment>
	);
};

export default Footer;
